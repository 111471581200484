import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/user',
    redirect: '/user/dashboard',
    component: () => import('@/layouts/User.vue'),
    children: [
      {
        path: '/user/dashboard',
        component: () => import('@/views/user/Dashboard.vue')
      },
      {
        path: '/user/bill',
        component: () => import('@/views/user/Bill.vue')
      },
      {
        path: '/user/vote',
        component: () => import('@/views/user/Vote.vue')
      }
    ]
  },
  {
    path: '*',
    component: () => import('@/views/NotFoundPage.vue')
  },
  {
    path: '/',
    redirect: '/auth/login',
    component: () => import('@/layouts/Auth.vue'),
    children: [
      {
        path: '/auth/login',
        component: () => import('@/views/auth/Login.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
